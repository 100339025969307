import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, Modal, Nav, Form} from 'react-bootstrap';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from "react-hook-form";
import { useAuth } from 'firebase-auth/AuthContext';
import {saveUserEmailAndPhone} from "data/quantum/quantumClientManager";
import classNames from "classnames";
import {getItemFromStore, setItemToStore} from "helpers/utils";
import {constants} from "config"
import {optinForSMS} from "../../helpers/NotificationHelper";

const SMSModal = () => {

  const autoShowModal = getItemFromStore(constants.showNotificationPopup, true)
  const { currentUser } = useAuth();
  const [modalShow, setModalShow] = React.useState(autoShowModal);
  // const [modalShow, setModalShow] = React.useState(true);

  /************************
   * YUP Form Validation
   ************************/
  const schema = yup.object({
    email: yup
      .string()
      .matches(
        /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        'Please enter a valid email'
      ),
    mobileNumber: yup
      .string()
      .matches(
        /^$|^\d{3}-\d{3}-\d{4}$/,
        'Mobile phone number is not valid'
      ),
  });

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '').slice(0, 10); // Keep only 10 digits
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: currentUser.email || '',  // Ensures a default empty string
      mobileNumber: formatPhoneNumber(currentUser.mobilePhone) || '',  // Ensures a default empty string
    }
  });

  /************************
   * Functions
   ************************/
  const onSubmit = async(data) => {

    if (data.email !== '') {
      currentUser.email = data.email
      currentUser.validEmail = true
    } else {
      currentUser.email = ''
      currentUser.validEmail = false
    }

    if (data.mobileNumber !== '') {
      currentUser.mobilePhone = data.mobileNumber
      currentUser.validPhone = true
    } else {
      currentUser.mobilePhone = ''
      currentUser.validPhone = false
    }
    await saveUserEmailAndPhone(currentUser)

    if (currentUser.validPhone)
      currentUser.contactId = await optinForSMS(currentUser)

    setModalShow(false)
  }

  const onHide = () => {
    setModalShow(false)
  }

  return (
    <>
      <Nav.Link variant="primary" onClick={() => setModalShow(true)}>
        <FontAwesomeIcon
          icon='bell'
          className="fs-0"
        />  Notifications
      </Nav.Link>

      <Modal
        show={modalShow}
        onHide={onHide}
        onShow={() => { setItemToStore(constants.showNotificationPopup, false) }}
        backdrop="static" // Prevents closing by clicking on the backdrop
        keyboard={false} // Prevents closing with the escape key
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Alert Settings
          </Modal.Title>
          {!autoShowModal && <Button variant="close" onClick={onHide} aria-label="Close" />}
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>

            <p>Input your email or mobile number to receive status updates. Leave the field blank if you <strong>do not</strong> want to receive updates.</p>
            {/* EMAIL */}
            <Form.Label className="fs-0">Email</Form.Label>
            <Controller
              name="email"
              render={({field, ref}) => (
                <Form.Control
                  ref={ref}
                  className={classNames({
                    'is-invalid': errors.email
                  })}
                  {...field}
                />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>

            {/* PHONE */}
            <Form.Label className="fs-0 mt-4">Mobile Number</Form.Label>
            <Controller
              name="mobileNumber"
              render={({field, ref}) => (
                <Form.Control
                  ref={ref}
                  {...field}
                  className={classNames({
                    'is-invalid': errors.mobileNumber
                  })}
                  onChange={(e) => {
                    const formatted = formatPhoneNumber(e.target.value);
                    field.onChange(formatted);
                  }}
                />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobileNumber?.message}
            </Form.Control.Feedback>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default SMSModal;