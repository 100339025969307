// export default [
let services = [
  { value: 'prescan', label: 'Prescan' },
  { value: 'postscanRepair', label: 'Postscan Repair' },
  { value: 'bsmAdasCalibration', label: 'BSM ADAS Calibration' },
  { value: 'adasCalibration', label: 'ADAS Calibration' },
  { value: 'frontRadarCalibration', label: 'Front Radar Calibration' },
  { value: '360ViewCameraCalibration', label: '360 View Camera Calibration' },
  { value: 'frontCameraCalibration', label: 'Front Camera Calibration' },
  { value: 'LaneAssistanceCalibration', label: 'Lane keep assistance Calibration' },
  { value: 'LaneWatchCameraCalibration', label: 'Lane Watch Camera Calibration' },
  { value: 'ActiveGrilleInitialization', label: 'Active Grille Initialization' },
  { value: 'ECUProgramming', label: 'ECU programming' },
  { value: 'harnessRepair', label: 'Harness repair' },
  { value: 'pigtailRepair', label: 'Pigtail Repair' },
  { value: 'diagnosticLevel1', label: 'Diagnostic Level 1' },
  { value: 'dashboardRandI', label: 'Dashboard R&I' },
  { value: 'mechanicalWork', label: 'Mechanical Work' },
  { value: 'suspensionWork', label: 'Suspension Work' },
  { value: 'ignitionSwitchAssemblyReplacement', label: 'Ignition Switch Assembly Replacement' },
  { value: 'hybridOrElectricUpdate', label: 'Hybrid or Electric Update' },
  { value: 'airbagOccupancyCalibration', label: 'Airbag Occupancy Calibration' },
  { value: 'airbagModuleProgramming', label: 'Airbag Module programming' },
  { value: 'airbagDiagnostics', label: 'Airbag Diagnostics' },
  { value: 'oemPigtailConnectors', label: 'OEM Pigtail Connectors' },
  { value: 'vehicleAlignment', label: 'Vehicle Alignment' }
].sort((a, b) => a.label.localeCompare(b.label));

export default services;

