import React, {useEffect, useState} from 'react';
import SoftBadge from 'components/common/SoftBadge';
import {Button, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from "react-select";
import { truncateTo128Chars } from "helpers/utils"
import { updateStatus, updateTech } from "data/quantum/quantumClientManager";
import { notifyEstimator, notifyBilling, notifyTech } from "helpers/NotificationHelper";
import {Link} from "react-router-dom"
import Flex from '../../common/Flex'

export const StatusBadge = ({status, padding = ''}) => {
  if (status === 'pending') {
    return <div className={`me-2 badge badge-soft-primary ${padding}`}>PENDING</div>
  } else if (status === 'in_progress') {
    return <div className={`me-2 badge badge-soft-warning ${padding}`}>IN PROGRESS</div>
  }
  return <div className={`me-2 badge badge-soft-success ${padding}`}>COMPLETED</div>
}

export const AdminStatusBadge = ({status, padding = ''}) => {
  if (status === 'pending') {
    return <div className={`me-2 badge badge-soft-danger ${padding}`}>PENDING</div>
  } else if (status === 'in_progress') {
    return <div className={`me-2 badge badge-soft-secondary ${padding}`}>IN PROGRESS</div>
  }
  return <div className={`me-2 badge badge-soft-success ${padding}`}>COMPLETED</div>
}

export const TicketsCardLayout = ({ currentUser, ticket, technicians, cancelRequest }) => {

  // eslint-disable-next-line
  const statusOptions = [
    {
      value : 'pending',
      label : 'Pending'
    },
    {
      value : 'in_progress',
      label : 'In Progress'
    },
    {
      value : 'completed',
      label : 'Completed'
    },
  ]

  const [selectedStatus, setSelectedStatus] = useState(statusOptions.find(status => status.value === ticket.status))

  const [modalShow, setModalShow] = useState(false);
  const [techValue, setTechValue] = useState({
    value: ticket.assignedTechnicianId,
    label: ticket.assignedTechnicianName
  });
  const [assignedTechName, setAssignedTechName] = useState('Pending')

  // Tickets updates
  // eslint-disable-next-line
  useEffect(() => {

    if (ticket.assignedTechnicianId === '') {
      setTechValue({
        value: '',
        label: 'Assign Tech...'
      })
      setAssignedTechName('Pending')
    } else {
      setTechValue({
        value: ticket.assignedTechnicianId,
        label: ticket.assignedTechnicianName
      })
      setAssignedTechName(ticket.assignedTechnicianName)
    }

    // Look for status change from another user
    if (selectedStatus.value !== ticket.status) {
      setSelectedStatus(statusOptions.find(status => status.value === ticket.status))
    }

  }, [ticket]);

  // Assigned tech updates
  // eslint-disable-next-line
  useEffect(() => {
    if (techValue.label === '') {
      setTechValue({
        value: '',
        label: 'Assign Tech...'
      })
    }
    else {
      setAssignedTechName(techValue.value === '' ? 'Pending' : techValue.label)
    }
  }, [techValue]);

  const assignTechnician = async(newTech) => {

    await updateTech(ticket, newTech)
    setTechValue(newTech)

    ticket.assignedTechnicianId = newTech.value
    ticket.assignedTechnicianName = newTech.label
    ticket.token = currentUser.fbUser.refreshToken
    notifyTech(ticket)

    const estimatorMsg = {
      purpose: 'Assigned',
      assignedTechnicianName: ticket.assignedTechnicianName,
      to: currentUser.email,
      subject: "[UPDATE] RO#" + ticket.repairOrderNumber,
      template: "client estimator template",
      userDocId: currentUser.docId,
      variables: {
        title: '[UPDATE]',
        ro: ticket.repairOrderNumber,
        license: ticket.licensePlate
      }
    }
    notifyEstimator(estimatorMsg, ticket.token)
  }

  const updateTicketStatus = async(status) => {

    setSelectedStatus(status)
    await updateStatus(ticket, status)

    // Guard against the case of assigned tech not existing
    if (ticket.assignedTechnicianId === '') return

    ticket.token = currentUser.fbUser.refreshToken
    const estimatorMsg = {
      purpose: status.value,
      assignedTechnicianName: ticket.assignedTechnicianName,
      to: currentUser.email,
      subject: "[UPDATE] RO#" + ticket.repairOrderNumber,
      template: "client estimator template",
      userDocId: ticket.estimatorId,
      variables: {
        title: '[UPDATE]',
        ro: ticket.repairOrderNumber,
        license: ticket.licensePlate
      }
    }
    notifyEstimator(estimatorMsg, ticket.token)

    if (status.value === 'completed') {
      notifyBilling(ticket)
    }
  }

  const requestCancel = async() => {
    await cancelRequest(ticket)
  }

  return (
    <div className="mb-3 ticket-container">

        <div className={`bg-white dark__bg-1100 align-items-center px-3 ${currentUser.position === 'manager' ? 'pt-0' : 'pt-3'} pb-3 rounded-3 shadow-sm w-100 admin-ticket`}>

          {/* TOP SECTION / TOOLBAR */}
          <div id="ticket-top-section" className="pb-3">

            {currentUser.position === 'manager' &&
            <Row className="pb-3">
              <AdminStatusBadge status={selectedStatus.value} padding="p-3" />
            </Row>
            }

            <Row>
              <Col xs={12} sm={6}>
                <SoftBadge pill bg='primary' className='me-2'>RO# {ticket.repairOrderNumber}</SoftBadge>
                <SoftBadge pill bg='warning' className='me-2'>License: {ticket.licensePlate}</SoftBadge>
              </Col>
              <Col xs={12} sm={6} className="pl-2">
                {currentUser.position === 'estimator' ?
                  <div className="text-end">
                    <StatusBadge status={ticket.status} />
                  </div>
                  :
                  <Select
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    options={statusOptions}
                    value={selectedStatus}
                    onChange={val => updateTicketStatus(val)}
                  />
                }
              </Col>
            </Row>
          </div>

          {/* MIDDLE SECTION / TOOLBAR */}
          <div className="border-top mb-x1"></div>
          <div className="d-flex align-items-start align-items-sm-center">
            <div className="ms-1">
              <Row>
                <Col xs="auto" className="me-2 p-0">
                  {ticket.services.map(s => (
                    <span key={s.value} className="ms-2 text-secondary badge rounded-pill bg-200">{s.label}</span>
                  ))}
                </Col>
              </Row>
            </div>
          </div>

          {/* MIDDLE SECTION / NOTES */}
          {ticket.notes !== '' &&
              <>
                <div className="border-top my-x1"></div>
                <Row>
                  <Col xs={12}>
                    {truncateTo128Chars(ticket.notes)}
                  </Col>
                  <Col xs={12}>
                    {ticket.notes.length > 128 &&
                        <>
                          <Link
                              className="d-block mt-2"
                              to={'#'}
                              onClick={() => setModalShow(true)}
                          >
                            view note...
                          </Link>
                          <Modal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="contained-modal-title-vcenter">Request Note</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>{ticket.notes}</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                  variant='falcon-primary'
                                  className='me-2 mb-1'
                                  onClick={() => setModalShow(false)}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                    }
                  </Col>
                </Row>
              </>
          }

          {/* BOTTOM SECTION / TOOLBAR */}
          <div className="border-bottom mt-4 mb-2"></div>
          <div className="ms-auto">
            <Row>
              <Col>

                {/* QUANTUM MANAGER VIEW */}
                {currentUser.locationId === 'quantum' &&
                  <>
                    <div className="mt-2" style={{ fontSize: '12px' }}>{ticket.estimatorId.toUpperCase()}</div>
                    <div className="mt-2" style={{ fontSize: '12px' }}>{ticket.createdMoment.format('MM/DD/YYYY hh:mm A')}</div>
                  </>
                }

                {/* ESTIMATOR VIEW */}
                {currentUser.position === 'estimator' &&
                  <>
                    <div className="mt-2" style={{ fontSize: '12px' }}><strong>Technician:</strong> <SoftBadge pill bg='info' className='me-2'>{assignedTechName}</SoftBadge></div>
                    <div className="mt-2" style={{ fontSize: '12px' }}>{ticket.createdMoment.format('MM/DD/YYYY hh:mm A')}</div>
                  </>
                }
              </Col>
              <Col>
                {currentUser.position === 'manager' &&
                    <Select
                        closeMenuOnSelect={true}
                        options={technicians}
                        classNamePrefix="react-select"
                        value={techValue}
                        onChange={val => assignTechnician(val)}
                    />
                }
                {currentUser.position === 'estimator' && ticket.status !== 'completed' &&
                  <Flex alignItems='center' justifyContent='end' className="mb-2" style={{height:'100%'}}>
                    <Button variant='outline-danger' size='sm' className='my-auto' onClick={requestCancel}>Cancel</Button>
                  </Flex>
                }
              </Col>
            </Row>
          </div>

        </div>
    </div>
  );
};

TicketsCardLayout.propTypes = {
  currentUser: PropTypes.object,
  ticket: PropTypes.object,
  technicians: PropTypes.arrayOf(PropTypes.object),
  cancelTicket: PropTypes.func
};
