import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import {Nav, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { useAuth } from 'firebase-auth/AuthContext';
import SMSModal from "../../quantum/SMSModal";

const TopNavRightSideNavItem = () => {

  const { currentUser } = useAuth();
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  return (

    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {currentUser !== undefined &&
        <Nav.Item as={'li'}>
          <SMSModal/>
        </Nav.Item>
      }
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

    </Nav>
  );
};

export default TopNavRightSideNavItem;
