export const version = '1.0.3';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'inverted'
};

export const constants = {
  showNotificationPopup: 'showNotificationPopup'
}

export const quantum = {
  isDebug: JSON.parse(process.env.REACT_APP_IS_DEBUG),
  blockRefresh: JSON.parse(process.env.REACT_APP_BLOCK_REFRESH)
}

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, constants };
