import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { TicketsCardLayout } from "./TicketCardLayout";
import usePagination from 'hooks/usePagination';
import React, {useEffect, useState} from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from "react-select"
import {notifyCancel} from "../../../helpers/NotificationHelper";
import {updateStatus} from "../../../data/quantum/quantumClientManager";

const ActiveTickets = ({data, setData}) => {

  // Filter by location
  const [currentLocation, setCurrentLocation] = useState({
    value: 'all_locations',
    label: 'All Locations'
  })

  // Filter by status
  const [checkedItems, setCheckedItems] = useState({});
  const filterItems = [
    { id: 1, status: 'pending', label: 'Pending' },
    { id: 2, status: 'in_progress', label: 'In Progress' },
    { id: 3, status: 'completed', label: 'Completed' },
  ];

  const [locationOptions, setLocationOptions] = useState([])
  const [technicianOptions, setTechnicianOptions] = useState([])
  const [allTickets, setAllTickets] = useState(data.requests);
  const [primaryTickets, setPrimaryTickets] = useState(allTickets);

  // Data prep
  useEffect(() => {

    // Sets the technicians select options
    // Used by Quantum Manager role only
    const newTechs = [];
    data.technicians.forEach((tech) => {
      newTechs.push({
        value: tech.docId,
        label: tech.firstName + ' ' + tech.lastName
      })
    })
    setTechnicianOptions(newTechs)

    // Sets location select options
    // Used by Quantum Manager role only
    if (data.currentUser.position !== 'estimator')
    {
      const locations = [{
        value: 'all_locations',
        label: 'All Locations'
      }];
      data.locations.forEach((location) => {
        locations.push({
          value: location.locationId,
          label: location.name + ' - ' + location.city + ' - ' + location.locationId.toUpperCase()
        })
      })
      setLocationOptions(locations)
    }

    // Filters down requests by technician
    // Used by technician role only
    let newData = [...data.requests]
    if (data.currentUser.position === 'technician') {
      newData = [...data.requests.filter(req =>
          req.assignedTechnicianId === data.currentUser.docId
      )]
    }

    // Here we will make sure all requests are always sorted
    // by descending date (newest to oldest)
    newData = newData.sort((a, b) => b.createdMoment - a.createdMoment);

    // Set the state
    setAllTickets(newData)
    setPrimaryTickets(newData)

  }, [data]);

  const {
    paginationState: {
      data: paginatedTicket,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage
  } = usePagination(primaryTickets, 20)

  // Filter effect
  useEffect(() => {

    // Filter for location
    let filteredRequests = []
    if (currentLocation.value === 'all_locations') {
      filteredRequests = [...data.requests]
    } else {
      filteredRequests = data.requests.filter(req =>
        req.location.locationId === currentLocation.value
      );
    }

    // Filter for technician should it be needed
    let newData = [...filteredRequests]
    if (data.currentUser.position === 'technician') {
      newData = [...filteredRequests.filter(req =>
        req.assignedTechnicianId === data.currentUser.docId
      )]
    }

    // THEN filter for status should it be needed
    const isNotEmpty = Object.keys(checkedItems).length;
    const allPropertiesNotFalse = !Object.values(checkedItems).every((value) => value === false);
    if (isNotEmpty && allPropertiesNotFalse) {
      newData = newData.filter(req => checkedItems[req.status])
    }

    setAllTickets(newData)
    setPrimaryTickets(newData)

  }, [currentLocation, checkedItems]);

  const filterByLocation = async(selectedLocation) => {
    setCurrentLocation(selectedLocation)
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newObj = {
      ...checkedItems,
      [name]: checked
    }
    setCheckedItems(newObj);
  };

  const cancelRequest = async (req) => {
    await updateStatus(req,  {
      value : 'cancelled',
      label : 'Cancelled'
    })
    req.token = data.currentUser.fbUser.refreshToken
    await notifyCancel(req)
    const remainingReq = data.requests.filter(obj => obj.docId !== req.docId);
    setData({
      ...data,
      requests: remainingReq
    })
  }

  const filterCss = data.currentUser.position !== 'estimator' ? 'text-center pt-3' : 'text-center pt-1 pb-1'

  return (
    <Row className="gx-3">
      <Col>
        <Card>

          <Card.Header className="pb-0">
            {data.currentUser.position !== 'estimator' &&
              <Select
                  closeMenuOnSelect={true}
                  options={locationOptions}
                  classNamePrefix="react-select"
                  value={currentLocation}
                  onChange={val => filterByLocation(val)}
              />
            }
            <div className={filterCss}>
              {filterItems.map((item) => (
                <Form.Check
                  key={item.status}
                  type="checkbox"
                  id={`checkbox-${item.status}`}
                  label={item.label}
                  name={`${item.status}`}
                  checked={checkedItems[item.status] || false}
                  onChange={handleCheckboxChange}
                  inline
                />
              ))}
            </div>
          </Card.Header>

          <Card.Body className="bg-light">

            {paginatedTicket.length > 0 ? paginatedTicket.slice(0, 12).map((ticket, index) => (
              <TicketsCardLayout
                key={index}
                currentUser={data.currentUser}
                ticket={ticket}
                technicians={technicianOptions}
                cancelRequest={cancelRequest}
              />
            )) :
              <h6 className='text-center p-3'>Add a new request to see it here...</h6>
            }

          </Card.Body>
          <Card.Footer className="d-flex justify-content-center">
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                className={classNames('me-2', { disabled: !canPreviousPage })}
                onClick={prevPage}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
            </div>

            <ul className="pagination mb-0">
              {paginationArray.map(page => (
                <li
                  key={page}
                  className={classNames({ active: currentPage === page })}
                >
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="page me-2"
                    onClick={() => goToPage(page)}
                  >
                    {page}
                  </Button>
                </li>
              ))}
            </ul>
            <div>
              <Button
                variant="falcon-default"
                size="sm"
                className={classNames({ disabled: !canNextPage })}
                onClick={nextPage}
              >
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default ActiveTickets;
