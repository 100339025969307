import React, { useState } from 'react';
import { Button, Card, Form, FormGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import MultiSelect from 'components/common/MultiSelect';
import classNames from 'classnames';
import quantumServices from "data/quantum/quantumServices";
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import { addRequest } from "data/quantum/quantumClientManager";
import {notifyEstimator, notifyManager} from "helpers/NotificationHelper";
import { useAuth } from 'firebase-auth/AuthContext';
import {debugLog} from "../../helpers/utils";

const ServiceForm = ({data, setData}) => {

  const { currentUser } = useAuth();
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { location, requests } = data

  const schema = yup
    .object({
      repairOrder: yup.string().min(1, 'Please enter your internal Repair Order #').required(),
      licensePlate: yup.string().min(1, 'Please enter your License Plate #').required(),
      services: yup.array().min(1, 'Please select one or more services.').required(),
      notes: yup.string().optional()
    })

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      repairOrder: '',  // default value for yourName
      licensePlate: '',  // default value for yourName
      services: [], // if you have default value for services
      notes: ''
    }
  });

  const onSubmit = async (requestData) => {

    const estimatorMsg = {
      purpose: 'pending',
      assignedTechnicianName: '',
      to: currentUser.email,
      subject: "[RECEIVED] RO#" + requestData.repairOrder,
      template: "client estimator template",
      userDocId: currentUser.docId,
      variables: {
        title: '[RECEIVED]',
        ro: requestData.repairOrder,
        license: requestData.licensePlate
      }
    }
    notifyEstimator(estimatorMsg, currentUser.fbUser.refreshToken)

    let newArray = data.requests;

    const result = await addRequest(data, requestData);
    const newRequest = {
      ...result
    }
    debugLog('------------------ NEW REQUEST', newRequest)
    newArray.push(newRequest)

    if (result !== false) {
        setData({
            ...data,
            requests: newArray
        })
    }

    notifyManager(newRequest, currentUser)

    reset({
      repairOrder: '',
      licensePlate: '',
      services: [],
      notes: ''
    });

    setIsSubmitted(true)
    setTimeout(() => {
      setIsSubmitted(false)
    }, 2000);
  };

  return (
    <Card>
      <Card.Header className="border-bottom border-200 px-0">
        <h5 className="mx-2 my-0 text-center">{location.name + ` - ` + location.city}</h5>
      </Card.Header>
      <Card.Body className="bg-light">

        <FormGroup>
          <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Label className="fs-0 mt-4">Repair Order #</Form.Label>
            <Controller
              name="repairOrder"
              render={({field, ref}) => (
                <Form.Control
                  ref={ref}
                  className={classNames({
                    'is-invalid': errors.services
                  })}
                  {...field}
                />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.repairOrder?.message}
            </Form.Control.Feedback>

            <Form.Label className="fs-0 mt-4">License Plate #</Form.Label>
            <Controller
              name="licensePlate"
              render={({field, ref}) => (
                <Form.Control
                  ref={ref}
                  className={classNames({
                    'is-invalid': errors.services
                  })}
                  {...field}
                />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.licensePlate?.message}
            </Form.Control.Feedback>

            <Form.Label className={`fs-0 mt-4`}>Services Needed</Form.Label>
            <Controller
              name="services"
              render={({field, ref}) => (
                <MultiSelect
                  ref={ref}
                  closeMenuOnSelect={false}
                  isMulti
                  options={quantumServices}
                  className={classNames({
                    'is-invalid': errors.services
                  })}
                  {...field}
                />
              )}
              control={control}
            />
            <Form.Control.Feedback type="invalid">
              {errors.services?.message}
            </Form.Control.Feedback>

            <Form.Label className="fs-0 mt-4">Notes</Form.Label>
            <Controller
              name="notes"
              render={({ field }) => <Form.Control as="textarea" rows={3} {...field} />}
              control={control}
            />

            {/* SUBMIT BUTTON */}
            {
              !isSubmitted ?
                <Button variant="primary" type="submit" className="mt-5 w-100" size="lg">
                  Submit
                </Button>
              :
                <Row className="align-items-center">
                  <Col xs={12} className="d-flex justify-content-center">
                    <Lottie animationData={checkPrimaryLight} loop={true} style={{height: '120px', width: '120px'}} />
                  </Col>
                  <Col xs={12} className="d-flex justify-content-center">
                    <h3>We're on it!</h3>
                  </Col>
                </Row>
            }
          </Form>
        </FormGroup>
      </Card.Body>
    </Card>
  )
}
ServiceForm.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
}
export default ServiceForm
