import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const app = initializeApp({
    apiKey: "AIzaSyBGt7jUv0N_J76bMWvuXF8N6aCMtqb8mGI",
    authDomain: "quantum-auto-tech.firebaseapp.com",
    projectId: "quantum-auto-tech",
    storageBucket: "quantum-auto-tech.appspot.com",
    messagingSenderId: "784490401882",
    appId: "1:784490401882:web:5515b0558c527ef97c4863",
    measurementId: "G-5SM6HCBC0R"
})

// Initialize Firebase Auth
export const db = getFirestore(app);
export const fbauth = getAuth()
export default app