import axios from "axios";
import { getUserFromDocId } from "../data/quantum/quantumClientManager";
import { quantum } from 'config'

const debugEmail = 'itjunkii@gmail.com'
const debugContactUid = "bVnoR3Ff1bN6spUBlIKlCs6kgei1"

const emails = {
  billing: quantum.isDebug === false ? 'billing@quantumautotech.com' : debugEmail,
  manager: quantum.isDebug === false ? 'admin@quantumautotech.com' : debugEmail
}

export const optinForSMS = async (currentUser) => {
  if (currentUser.validPhone === false) return
  const result = await postToApi('optin', {}, currentUser.fbUser.refreshToken)
  return result.data
}

export const notifyManager = (request, currentUser) => {

  const smsMsg = `
  New service request:
  --------------------
  Estimator: ${request.estimatorId.toUpperCase()}
  RO: ${request.repairOrderNumber}
  License: ${request.licensePlate}
  `

  const obj = {
    to: emails.manager,
    subject: "New Request: [" + request.estimatorId.toUpperCase() + "]",
    template: "estimator template",
    variables: {
      estimator: request.estimatorId.toUpperCase(),
      ro: request.repairOrderNumber,
      license: request.licensePlate
    },
    contactUid: 'CmnePq9PTLds8PLrVsaPOjM0BPl1',
    smsMsg: smsMsg
  }
  postToApi('notify', obj, currentUser.fbUser.refreshToken)
}

export const notifyCancel = async (request) => {

  const emailArr = [emails.manager]

  const smsMsg = `
  Cancelled request:
  --------------------
  Estimator: ${request.estimatorId.toUpperCase()}
  RO: ${request.repairOrderNumber}
  License: ${request.licensePlate}
  `

  if (request.assignedTechnicianId !== '') {
    const pof = await getUserFromDocId(request.assignedTechnicianId)
    const pofEmail = quantum.isDebug === false ? pof.email : debugEmail
    emailArr.push(pofEmail)
  }

  const strEmails = emailArr.join(',')

  const obj = {
    to: strEmails,
    subject: `[CANCELLED] | ${request.estimatorId} | RO# ${request.repairOrderNumber}`,
    template: "technician template",
    contactUid: 'CmnePq9PTLds8PLrVsaPOjM0BPl1',
    smsMsg: smsMsg,
    variables: {
      title: 'CANCELLED',
      estimator: request.estimatorId.toUpperCase(),
      ro: request.repairOrderNumber,
      license: request.licensePlate,
      notes: request.notes
    }
  }
  postToApi('notify', obj, request.token)
}

export const notifyEstimator = async (obj, token) => {

  const pof = await getUserFromDocId(obj.userDocId)
  obj.to = quantum.isDebug === false ? pof.email : debugEmail
  obj.contactUid = quantum.isDebug === false ? pof.uid : debugContactUid

  let updateMsg = 'Pending'
  let message = `Your request is confirmed and we are actively working on it.`

  if (obj.purpose === 'Assigned') {
    updateMsg = 'In Progress'
    message = `${obj.assignedTechnicianName} has been assigned your request.`
  }

  if (obj.purpose === 'in_progress') {
    updateMsg = 'In Progress'
    message = `${obj.assignedTechnicianName} has started your request.`
  }

  if (obj.purpose === 'completed') {
    updateMsg = 'Completed'
    message = `${obj.assignedTechnicianName} has completed your request.`
  }

  obj.subject           += ' | ' + updateMsg
  obj.variables.message = message
  obj.smsMsg            = `
  [UPDATE]
  
  ${message}
  
  RO#: ${obj.variables.ro}
  License: ${obj.variables.license}
  `

  postToApi('notify', obj, token)
}

export const notifyTech = async (request) => {

  const pof = await getUserFromDocId(request.assignedTechnicianId)
  const pofEmail = quantum.isDebug === false ? pof.email : debugEmail
  const pofContactUid = quantum.isDebug === false ? pof.uid : debugContactUid

  const smsMsg = `
  [TECHNICIAN]
  New service request:
  --------------------
  Estimator: ${request.estimatorId.toUpperCase()}
  RO: ${request.repairOrderNumber}
  License: ${request.licensePlate}
  `

  const obj = {
    to: pofEmail,
    subject: "[NEW REQUEST] RO#" + request.repairOrderNumber,
    template: "technician template",
    contactUid: pofContactUid,
    smsMsg: smsMsg,
    variables: {
      title: 'NEW SERVICE REQUEST',
      estimator: request.estimatorId.toUpperCase(),
      ro: request.repairOrderNumber,
      license: request.licensePlate,
      notes: request.notes
    }
  }
  postToApi('notify', obj, request.token)
}

export const notifyBilling = async (request) => {

  let servicesDone = ''
  request.services.forEach((serviceObj) => {
    servicesDone += serviceObj.label + ', '
  })

  const smsMsg = `
  Billing email sent:
  --------------------
  Estimator: ${request.estimatorId.toUpperCase()}
  RO: ${request.repairOrderNumber}
  License: ${request.licensePlate}
  `

  const obj = {
    to: emails.billing,
    subject: "[COMPLETED] RO#" + request.repairOrderNumber + ' | ' + request.estimatorId.toUpperCase(),
    template: "billing template",
    contactUid: 'CmnePq9PTLds8PLrVsaPOjM0BPl1',
    smsMsg: smsMsg,
    variables: {
      technician: request.assignedTechnicianName,
      estimator: request.estimatorId.toUpperCase(),
      ro: request.repairOrderNumber,
      license: request.licensePlate,
      services: servicesDone
    }
  }
  postToApi('notify', obj, request.token)
}


const postToApi = async (endpoint, obj, token) => {
  return axios({
    method: 'POST',
    url: `https://api.quantumautotech.com/${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : token
    },
    data: obj
  })
}