import {collection, doc, getDocs, query, getDoc, setDoc, updateDoc, Timestamp, where} from "firebase/firestore";
import {db} from "firebase-auth/config";
import moment from 'moment/moment'
import {debugLog} from "../../helpers/utils";

const getRequestFromTicket = async (ticket) => {
  const docRef = doc(db, "clients", ticket.location.docId)
  const requestsRef = collection(docRef, 'requests')
  const requestQuery = query(requestsRef, where('docId', '==', ticket.docId))

  let requestToUpdateDoc = null
  const requestSnapshot = await getDocs(requestQuery);
  requestSnapshot.forEach((reqDoc) => {
    requestToUpdateDoc = reqDoc
    return false
  })
  return requestToUpdateDoc
}

export const getUserFromDocId = async (docId) => {

  const docRef = doc(db, "users", docId)
  const estimatorDoc = await getDoc(docRef)

  return estimatorDoc.data()
}

export const updateStatus = async (ticket, status) => {

  const requestToUpdateDoc = await getRequestFromTicket(ticket)

  try {
    await updateDoc(requestToUpdateDoc.ref, {
      status: status.value,
    });
  } catch (error) {
    debugLog(error)
  }
}

export const updateTech = async (ticket, technician) => {

  const requestToUpdateDoc = await getRequestFromTicket(ticket)

  try {
    await updateDoc(requestToUpdateDoc.ref, {
      assignedTechnicianName: technician.label,
      assignedTechnicianId: technician.value,
    });
  } catch (error) {
    debugLog(error)
  }
}

export const addRequest = async (userData, requestData) => {

  const unixTimestamp = Math.floor(Date.now() / 1000);
  const docId = userData.currentUser.docId + '_' + unixTimestamp

  const docData = {
    docId: docId,
    location: userData.location,
    estimatorId: userData.currentUser.docId,
    estimatorName: userData.currentUser.firstName + ' ' + userData.currentUser.lastName,
    repairOrderNumber: requestData.repairOrder,
    licensePlate: requestData.licensePlate,
    timestamp: Timestamp.fromDate(new Date()),
    completedTimestamp: Timestamp.fromDate(new Date()),
    assignedTechnicianName: '',
    assignedTechnicianId: '',
    status: 'pending',
    services: requestData.services,
    notes: requestData.notes
  }

  const docRef = doc(db, "clients", userData.location.docId)
  const requestsRef = collection(docRef, 'requests')

  try {
    await setDoc(doc(requestsRef, docId), docData);

    docData.createdMoment = moment.unix(docData.timestamp.seconds)
    docData.completedMoment = moment.unix(docData.completedTimestamp.seconds)

    return docData
  } catch (error) {
    debugLog(error)
    return false
  }
}

export const saveUserEmailAndPhone = async(currentUser) => {
  try {
    const userRef = doc(db, "users", currentUser.docId)
    await updateDoc(userRef, {
      email: currentUser.email,
      validEmail: currentUser.validEmail,
      mobilePhone: currentUser.mobilePhone,
      validPhone: currentUser.validPhone
    });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
}

export const getData = async (currentUser) => {

  // Set our default value for our validPhone
  if (currentUser.validEmail === undefined) {
    currentUser.validEmail = false
  }
  if (currentUser.validPhone === undefined) {
    currentUser.validPhone = false
  }

  // Object that will eventually be returned
  let clientData = {
    currentUser: currentUser,
    location: {},
    locations: [],
    technicians: [],
    requests: [],
    timeStamp: Math.floor(Date.now() / 1000)
  };

  // Extract technicians and user
  const usersRef = collection(db, "users");
  const userQuery = query(usersRef);
  const userQuerySnapshot = await getDocs(userQuery)
  userQuerySnapshot.forEach((doc) => {

    const pos = doc.data().position
    if (pos === 'technician' || pos === 'manager') {
      clientData.technicians.push({
          docId: doc.id,
          ...doc.data(),
          timeStamp: Math.floor(Date.now() / 1000)
        }
      )
    }

  });

  // Get location data
  const clientsRef = collection(db, "clients");

  let clientsQuery
  if (currentUser.position === 'manager')
    clientsQuery = query(clientsRef);
  else if (currentUser.position === 'estimator')
    clientsQuery = query(clientsRef, where("estimators", "array-contains", currentUser.docId));
  else if (currentUser.position === 'technician')
    clientsQuery = query(clientsRef);

  const clientQuerySnapshot = await getDocs(clientsQuery);
  for (let doc of clientQuerySnapshot.docs) {

    // If there is one location cool for now
    clientData.location = {
      docId: doc.id,
      ...doc.data(),
      timeStamp: Math.floor(Date.now() / 1000)
    }

    // Estimators will have one, managers will have all
    clientData.locations.push({
      docId: doc.id,
    ...doc.data(),
      timeStamp: Math.floor(Date.now() / 1000)
    })

    // Get requests sub-collection
    const requestsRef = collection(doc.ref, 'requests')

    const requestQuery = currentUser.position === 'estimator' ?
      query(requestsRef, where('estimatorId', '==', currentUser.docId)) :
      query(requestsRef)

    const requestSnapshot = await getDocs(requestQuery);
    requestSnapshot.forEach((reqDoc) => {

      if (reqDoc.data().status === 'cancelled') return

      const createdMoment =  moment.unix(reqDoc.data().timestamp.seconds)
      const completedMoment = moment.unix(reqDoc.data().completedTimestamp.seconds)

      // Fix for ticket status object type
      let req = reqDoc.data()
      if (req.status !== null && typeof req.status === 'object') {
        req.status = req.status.value
      }

      // Anything completed over two weeks ago should be ignored
      if (moment().diff(completedMoment, 'days') >= 14) return

      // Not older than two weeks we add
      clientData.requests.push({
        ...req,
        createdMoment: createdMoment,
        completedMoment: completedMoment,
        docId: reqDoc.id,
        timeStamp: Math.floor(Date.now() / 1000)
      })

    })
  }

  // Sort by date
  clientData.requests = clientData.requests.sort((a, b) => b.createdMoment - a.createdMoment);

  debugLog('CLIENT DATA', clientData)

  return clientData;
}