import QuantumLogo from "assets/img/logos/quantum-symbol-no-circle.svg";
import {Container, Spinner} from "react-bootstrap";
import React from "react";

const QuantumSpinner = ({paddingTop, paddingBottom}) => {

  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100">
      <div style={{
        paddingTop: paddingTop,
        paddingBottom: paddingBottom
      }} className="">
        <img
          src={QuantumLogo}
          alt=""
          width={130}
          style={{ marginBottom: '33px' }}
          className="d-block mx-auto quantum-logo"
        />
        <Spinner className="quantum-spinner-inside-logo" animation="grow" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </Container>
  )
}
export default QuantumSpinner