import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import { useAuth } from 'firebase-auth/AuthContext';

const Footer = () => {

  const { isUser, currentUser, logout} = useAuth();

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            <br className="d-sm-none"/> {new Date().getFullYear()} &copy;{' '}
            Quantum Automotive Technologies
          </p>
        </Col>
        <Col sm="auto">

          {isUser &&
            <p className="mb-0 text-600">
              {`${currentUser.firstName} ${currentUser.lastName}`}
              &nbsp; &nbsp;
              <a
                onClick={async (e) => {
                  e.preventDefault()
                  logout()
                }}
                href="#"
              >
                logout
              </a>
            </p>
          }
        </Col>
      </Row>
    </footer>
  )
}

export default Footer;
