import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword
} from 'firebase/auth';
import {useLocation, useNavigate} from 'react-router-dom';
import {db, fbauth} from './config';
import {collection, getDocs, query, where} from "firebase/firestore";
import QuantumSpinner from "components/quantum-spinner/QuantumSpinner";

export const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [isUser, setIsUser] = useState(false)
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    // Route methods
    const navigate = useNavigate();
    const currLocation = useLocation();

    function signup(email, password) {
        return createUserWithEmailAndPassword(fbauth, email, password)
    }

    function signin(email, password) {
        return signInWithEmailAndPassword(fbauth, email, password)
    }

    function logout() {
        signOut(fbauth).then(() => {
            navigate('/login')
        })
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(fbauth, email)
    }

    // Maybe doesn't work?
    function emailUpdate(email) {
        return updateEmail(currentUser, email)
    }

    function passwordUpdate(password) {
        return updatePassword(currentUser, password)
    }

    useEffect(() => {

        const getUserObj = async (user) => {
            const usersRef = collection(db, "users");
            const userQuery = query(usersRef, where('uid', '==', user.uid))
            return await getDocs(userQuery)
        }

        return onAuthStateChanged(fbauth, user => {
            if (!user) {
                if (currLocation.pathname !== '/login') {
                    navigate('/login')
                }
                setLoading(false)
            } else {
                getUserObj(user).then(docSnapshot => {

                    const doc = docSnapshot.docs[0]
                    setCurrentUser({
                        fbUser: user,
                        docId: doc.id,
                        ...doc.data()
                    })
                    setIsUser(true)
                    if (window.location.pathname === '/login') {
                        navigate('/')
                    }
                    setLoading(false)
                })
            }

        })
    }, [])

    const value = {
        isUser,
        currentUser,
        signin,
        signup,
        logout,
        resetPassword,
        emailUpdate,
        passwordUpdate
    }

    if (loading) {
        return (
          <QuantumSpinner/>
        )
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}