import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import QuantumSpinner from "components/quantum-spinner/QuantumSpinner";
import { useAuth } from 'firebase-auth/AuthContext';
import ServiceForm from "./ServiceForm";
import ActiveTickets from "./tickets/ActiveTickets";
import { getData } from "data/quantum/quantumClientManager";
import { quantum } from 'config'

const QuantumView = () => {

  const {currentUser} = useAuth();
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})

  // eslint-disable-next-line
  useEffect(() => {

    if (currentUser === undefined) return

    let refreshInterval = 360000

    // eslint-disable-next-line
    const rerenderData = () => {
      fetchData().then(fsData => {
        const dataObj = {
          timeStamp: Math.floor(Date.now() / 1000),
          currentUser,
          ...fsData
        }
        setData(dataObj)
        setIsLoading(false)

        if (dataObj.currentUser.position !== 'estimator') refreshInterval = 180000
      })
    }

    // eslint-disable-next-line
    const fetchData = async () => {
      return await getData(currentUser)
    }

    rerenderData()

    // Set up an interval to refetch data every 6 minutes
    if (quantum.blockRefresh === false) {
      const intervalId = setInterval(rerenderData, refreshInterval);
      return () => clearInterval(intervalId);
    }

  }, [currentUser]);

  if (isLoading) {
    return (
      <QuantumSpinner paddingBottom='138px'/>
    )
  }

  return (
    <Container>
      {currentUser.locationId !== 'quantum' ?
          <Tabs
            defaultActiveKey="new_request"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="new_request" title="New Request">
              <ServiceForm data={data} setData={setData}/>
            </Tab>
            <Tab eventKey="peding_requests" title="Active Requests">
              <ActiveTickets data={data} setData={setData} />
            </Tab>
          </Tabs>
        :
        <ActiveTickets data={data} setData={setData} />
      }
    </Container>
  );

};
export default QuantumView;
